<template>
  <base-section id="we-help-your-success">
    <base-section-heading title="bars and restaurants" outlined />
    <!-- APPONE -->
    <v-container class="con1">
      <v-row class="d-flex justify-content-center">
        <v-col cols="12" md="3">
          <v-card class="mx-auto" max-width="344">
            <v-img
              class="elevation-3"
              data-aos="flip-up"
              data-aos-duration="1000"
              contain
              src="@/assets/wplunkket.jpg"
              color="grey lighten-1"
              tile
            />
            <base-btn
              href="https://www.wplunket.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              VIEW WEBSITE
            </base-btn>
          </v-card>
        </v-col>
        <!--  -->
        <v-col cols="12" md="3">
          <v-card class="mx-auto" max-width="344">
            <v-img
              class="elevation-3"
              data-aos="flip-up"
              data-aos-duration="1000"
              contain
              src="@/assets/thelockkeeper.jpg"
              color="grey lighten-1"
              tile
            />
            <base-btn
              href="https://www.thelockkeeper.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              VIEW WEBSITE
            </base-btn>
          </v-card>
        </v-col>
        <!--  -->
        <v-col cols="12" md="3">
          <v-card class="mx-auto" max-width="344">
            <v-img
              class="elevation-3"
              data-aos="flip-up"
              data-aos-duration="1000"
              contain
              src="@/assets/mooneys.jpg"
              color="grey lighten-1"
              tile
            />
            <base-btn
              href="https://www.mooneysbar.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              VIEW WEBSITE
            </base-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="mx-auto" max-width="344">
            <v-img
              class="elevation-3"
              data-aos="flip-up"
              data-aos-duration="1000"
              contain
              src="@/assets/mulliganandhaines.jpg"
              color="grey lighten-1"
              tile
            />
            <base-btn
              href="https://www.mulliganandhaines.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              VIEW WEBSITE
            </base-btn>
          </v-card>
        </v-col>
      </v-row>
      <!--
      -->
      <!--  -->
      <v-row class="d-flex justify-content-center">
        <v-col cols="12" md="3">
          <v-card class="mx-auto" max-width="344">
            <v-img
              class="elevation-3"
              data-aos="flip-up"
              data-aos-duration="1000"
              contain
              src="@/assets/thesackville.jpg"
              color="grey lighten-1"
              tile
            />
            <base-btn
              href="http://thesackvillelounge.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              VIEW WEBSITE
            </base-btn>
          </v-card>
        </v-col>
        <!--  -->
        <v-col cols="12" md="3">
          <v-card class="mx-auto" max-width="344">
            <v-img
              class="elevation-3"
              data-aos="flip-up"
              data-aos-duration="1000"
              contain
              src="@/assets/dalys.png"
              color="grey lighten-1"
              tile
            />
            <base-btn
              href="https://www.dalysofedenquay.ie/"
              style="width: 100%;"
              data-aos="flip-up"
            >
              VIEW WEBSITE
            </base-btn> </v-card
          >``
        </v-col>
        <!--  -->
        <v-col cols="12" md="3">
          <v-card class="mx-auto" max-width="344">
            <v-img
              class="elevation-3"
              data-aos="flip-up"
              data-aos-duration="1000"
              contain
              src="@/assets/thejames.png"
              color="grey lighten-1"
              tile
            />
            <base-btn
              href="https://www.dalysofedenquay.ie/jamesconnolly"
              style="width: 100%;"
              data-aos="flip-up"
            >
              VIEW WEBSITE
            </base-btn>
          </v-card>
        </v-col>
        <!--  -->
      </v-row>
    </v-container>
    <!-- END -->
  </base-section>
</template>

<script>
export default {
  name: "Venues"
};
</script>
